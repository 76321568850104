import * as Credential from "credential";

$(document).on("submit", "#credentials-form", function(event) {
  event.preventDefault();

  $.ajax({
    url: event.target.getAttribute("action"),
    method: event.target.getAttribute("method"),
    data: {
      authenticity_token: event.target.querySelector(
        "input[name='authenticity_token']"
      ).value,
    },
    success: function(data, status, xhr) {
      var credentialOptions = data;
      var credential_nickname = event.target.querySelector(
        "input[name='credential[nickname]']"
      ).value;
      var callback_url = `/credentials/callback?credential_nickname=${credential_nickname}`;

      Credential.create(encodeURI(callback_url), credentialOptions);
    },
  });
});

$(document).on("click", "#webauth-login", function(event) {
  event.preventDefault();

  if(document.getElementById('new_user').querySelector("input[name='user[email]']").value){
      $.ajax({
        url: "/sessions",
        method: "post",
        data: {
          authenticity_token: document.getElementById('new_user').querySelector(
            "input[name='authenticity_token']"
          ).value,
          username: document.getElementById('new_user').querySelector(
            "input[name='user[email]']"
          ).value
        },
        success: function(data, status, xhr) {
          var credentialOptions = data;
          Credential.get(credentialOptions);
        },
      });
    }
});
